import React, { useEffect,useState } from "react";
import styles from "./style.module.css";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import pdf from "../../assets/act.pdf";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Offcanvas, Nav, Button } from "react-bootstrap";
import { FiMenu } from "react-icons/fi"
import "./TopNavbar.css"
import { positions } from "react-alert";

const TopNavbar = () => {
  const clickHandler = () => {
    window.open(pdf);
  };
  useEffect(() => {
    require("bootstrap/dist/js/bootstrap.bundle.min");
  }, []);

  let userlogintoken = localStorage.getItem('userToken')



  const [navbarColor, setNavbarColor] = useState('transparent');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [show, setShow] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setNavbarColor('white');
    } else {
      setNavbarColor(isMobile ? 'white' : 'transparent');
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const userToken = localStorage.getItem("userToken");
  const adminToken = localStorage.getItem("superAdminToken");

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    // Initial check for mobile screen
    setNavbarColor(isMobile ? 'white' : 'transparent');

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile]);

  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
    {/* Top Navbar for Desktop */}
    {!isMobile && (
      <nav
        className={`navbar navbar-expand-lg ${styles.custom_nav}`}
        style={{
          backgroundColor: navbarColor,
          position: navbarColor === 'white' ? 'sticky' : 'absolute',
          top: 0,
          width: '100%',
          zIndex: 1000,
          transition: 'background-color 0.3s ease',
        }}
      >
        <div className="container-fluid">
          <div className="d-flex" style={{ alignItems: "center" }}>
            <Link className="navbar-brand" to="/">
              <img
                src={logo}
                className="d-inline-block mb-2"
                height={60}
                width={60}
                alt=""
              />
            </Link>
            <div>
              <h5
                className="me-3 d-none d-md-block"
                style={{
                  fontSize: "1.2rem",
                  marginBottom: "0",
                  color: "#000",
                  fontWeight: "600",
                }}
              >
                उपभोक्ता अधिकार संगठन <br />
                <span
                  style={{
                    fontSize: "1rem",
                    fontFamily: "Poppins, serif",
                  }}
                >
                  Consumer Rights Organisation
                </span>
              </h5>
            </div>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            id="navbarToggler"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item me-3">
                <Link
                  style={{ fontSize: 14, fontFamily: "Poppins, serif" }}
                  className={`nav-link active ${styles.navItem}`}
                  aria-current="page"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item me-3">
                <Link
                  style={{
                    fontSize: 14,
                    fontWeight: 700,
                    color: "#000",
                  }}
                  className={`nav-link active ${styles.navItem}`}
                  aria-current="page"
                  to="/about/aboutcro"
                >
                  About us
                </Link>
              </li>
              <li className="nav-item me-3">
                <Link
                  style={{
                    fontSize: 14,
                    fontWeight: 700,
                    color: "#000",
                  }}
                  className={`nav-link active ${styles.navItem}`}
                  aria-current="page"
                  to="/movement/billlekedekho"
                >
                  Movement
                </Link>
              </li>
              <li className="nav-item me-3">
                <Link
                  style={{
                    fontSize: 14,
                    fontWeight: 700,
                    color: "#000",
                  }}
                  className={`nav-link active ${styles.navItem}`}
                  aria-current="page"
                  to="/committee"
                >
                  Committee
                </Link>
              </li>
            </ul>

            <Link to="/consumer-complaint-with-consumer-right-helpline">
              <button className={`me-3 ${styles.home_btns}`}>File a Complaint</button>
            </Link>

            {adminToken ? (
              <Link to="/complaint/adminPanel/superAdminDashboardView">
                <button className={`me-3 ${styles.home_btns}`}>Dashboard</button>
              </Link>
            ) : userToken ? (
              <Link to="/complaint/userDashboard/">
                <button className={`${styles.home_btns}`}>Profile</button>
              </Link>
            ) : (
              <Link to="/complaint/register">
                <button className={`${styles.home_btns}`} style={{ fontSize: 12 }}>
                  Become a Member
                </button>
              </Link>
            )}
            {
              userlogintoken === ''?
              <Link to="/complaint/userLogin" style={{marginLeft:10}}>
              <button className={`me-3 ${styles.home_btns}`}>Sign In</button>
            </Link>
              :
              <></>
            }
              
          </div>

        </div>
      </nav>
    )}

    {/* Side Navbar for Mobile */}
    {isMobile && (
      <>
          <div  className={styles.mobile_navbar}>
            <div style={{width:"70%",display:"flex",alignItems:"center",marginLeft:5}}>
            <Link className="navbar-brand" to="/">
              <img
                src={logo}
                className="d-inline-block mb-2"
                height={50}
                width={50}
                alt=""
              />
            </Link>

            <div>
            <h5
                style={{
                  fontSize:12,
                  fontFamily: "Poppins, serif",
                  color:"#000"
                }}
              >
                उपभोक्ता अधिकार संगठन <br />
                <span
                  style={{
                    fontSize:12,
                    fontFamily: "Poppins, serif",
                    color:"#000"
                  }}
                >
                  Consumer Rights Organisation
                </span>
              </h5>
            </div>
            
            </div>
            <div style={{width:"30%",height:"100%",display:"flex",justifyContent:"flex-end",alignItems:"center"}}>
            <button onClick={handleShow}  style={{padding:0,width:50,height:50,background:"transparent",marginRight:5}}>
              <FiMenu size={25} color="#000" />
            </button>
            </div>
          </div>

         {/* Sidebar */}
        
   
      </>
    )}

<Offcanvas show={show} onHide={handleClose}  style={{width:300,}}>
        <Offcanvas.Header closeButton>
           
           <div style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%"}}>
           <div style={{width:"35%",display:"flex",alignItems:"center",marginLeft:2}}>
           <Link className="navbar-brand" to="/">
              <img
                src={logo}
                className="d-inline-block mb-2"
                height={50}
                width={50}
                alt=""
              />
            </Link>
            </div>
            <h5
                style={{
                  fontSize:12,
                  fontFamily: "Poppins, serif",
                  color:"#000"
                }}
              >
                उपभोक्ता अधिकार संगठन <br />
                <span
                  style={{
                    fontSize:12,
                    fontFamily: "Poppins, serif",
                    color:"#000"
                  }}
                >
                  Consumer Rights Organisation
                </span>
              </h5>
           </div>



        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul style={{padding:"10px"}}>
            <li  style={{paddingTop:7,paddingBottom:7}} >
            <Link
                 style={{paddingTop:10,paddingBottom:10,fontSize:15,fontWeight:700,color:"#000",paddingLeft:0,marginLeft:0}} className={styles.navItem1}
                  aria-current="page"
                  to="/">
              Home
              </Link>
            </li>
            <li style={{paddingTop:7,paddingBottom:7}}>
            <Link
                  style={{paddingTop:10,paddingBottom:10,fontSize:15,fontWeight:700,color:"#000"}} className={styles.navItem1}
                  aria-current="page"
                  to="/about/aboutcro">
                   About us
            </Link>
            </li>
            <li style={{paddingTop:7,paddingBottom:7}}>
            <Link
                  style={{paddingTop:10,paddingBottom:10,fontSize:15,fontWeight:700,color:"#000"}} className={styles.navItem1}
                  aria-current="page"
                  to="/movement/billlekedekho"
                >
              Movement
           </Link>
            </li>
            <li style={{paddingTop:7,paddingBottom:7}}>
            <Link
                 style={{paddingTop:10,paddingBottom:10,fontSize:15,fontWeight:700,color:"#000"}} className={styles.navItem1}
                  aria-current="page"
                  to="/committee"
                >
              Committee
            </Link>
            </li>
            <li style={{paddingTop:7,paddingBottom:7}}>
            <Link to="/consumer-complaint-with-consumer-right-helpline"
            style={{paddingTop:10,paddingBottom:10,fontSize:15,fontWeight:700,color:"#000"}} className={styles.navItem1}
            aria-current="page"
            >
              File Complaint
              </Link>
              </li>
              {adminToken ? (
                <li style={{paddingTop:7,paddingBottom:7,color:"#000"}}>
              <Link tyle={{paddingTop:10,paddingBottom:10,fontSize:15,fontWeight:700,color:"#000"}} className={styles.navItem1} to="/complaint/adminPanel/superAdminDashboardView">
                Dashboard
              </Link>
              </li>
            ) : userToken ? (
              <li style={{paddingTop:7,paddingBottom:7,color:"#000"}}>
              <Link tyle={{paddingTop:10,paddingBottom:10,fontSize:15,fontWeight:700,color:"#000"}} className={styles.navItem1} to="/complaint/userDashboard/">
               Profile
              </Link>
              </li>
            ) : (
              <li style={{paddingTop:7,paddingBottom:7,color:"#000"}}>
              <Link tyle={{paddingTop:10,paddingBottom:10,fontSize:15,fontWeight:700,color:"#000"}} className={styles.navItem1} to="/complaint/register">
                  Become a Member
              </Link>
              </li>
            )}
             <li style={{paddingTop:7,paddingBottom:7}}>
            <Link to="/consumer-complaint-with-consumer-right-helpline"
            style={{paddingTop:10,paddingBottom:10,fontSize:15,fontWeight:700,color:"#000"}} className={styles.navItem1}
            aria-current="page"
            >
              Sign In
              </Link>
              </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
  </>
  );
};

export default TopNavbar;
