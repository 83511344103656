import { Link } from "react-router-dom";
import Footer from "../footer/Footer";
import Navbar from "../navbar/TopNavbar";
import React, { useEffect } from "react";
import slider3 from "../../assets/sliderNew3.jpg";
import slider4 from "../../assets/sliderNew4.jpg";
import { Helmet } from "react-helmet";
import pdf from "../../assets/act.pdf";
import { Container, Row,Col } from "react-bootstrap";
import { BsDisplay } from "react-icons/bs";

const AboutCro = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const clickHandler = () => {
    window.open(pdf);
  };
  useEffect(() => {
    require("bootstrap/dist/js/bootstrap.bundle.min");
  }, []);

  return (
    <>
      <Navbar />
      <Helmet>
        <title>About Consumer Right Origination CRO</title>
        <meta
          name="description"
          content="Learn about the origination and history of consumer rights. Understand how to protect your rights and resolve complaints effectively."
        />
        <meta property="og:title" content="About Consumer Right Origination" />
        <meta
          property="og:description"
          content="Discover the history and importance of consumer rights and learn how to submit and resolve complaints."
        />
        <meta property="og:type" content="website" />
      </Helmet>
    
     
      <br></br>
      <div className="about_section_main">
         <div className="about-min-navbar">
      <ul >
        
        <li>
          <Link onClick={clickHandler}  style={{color:"#fff"}}>
            About Consumer Act /
          </Link>
        </li>
        <li >
          <Link  to="/about/aboutconsumer" style={{color:"#fff"}}>
            About Consumer Rights
          </Link>
        </li>
      </ul>
      </div>
        <section className="about-section">
          <Container>
          <Row style={{marginTop:-80}}>
              <Col md={6} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
              <div className="inner-column wow fadeInLeft">
                  <figure className="image-1">
                    <a
                      href="#"
                      className="lightbox-image"
                      data-fancybox="images"
                    >
                      <img
                        title="Rahul Kumar Yadav"
                        src={slider4}
                        alt
                        style={{ height: "15.5rem",borderRadius:10 }}
                      />
                    </a>
                  </figure>
                  <figure className="image-1">
                    <a
                      href="#"
                      className="lightbox-image"
                      data-fancybox="images"
                    >
                      <img
                        title="Rahul Kumar Yadav"
                        src={slider3}
                        alt
                        style={{ height: "15.5rem" ,borderRadius:10}}
                      />
                    </a>
                  </figure>
                </div>
              </Col> 
              <Col md={6} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
              <div >
              <div className="sec-title">
                    <span className="title">About CRO</span>
                    <h2>History Behind The Formation Of CRO</h2>
                  </div>
                  <div className="text">
                    In 2010 Late Mr. D.P.Sharma and four activists started CRO
                    they felt that something needed to be done to improve the
                    situation of the common consumer. In this age the moral
                    ethics in trade or business has fallen on ground for maximum
                    profits. Even eatables are being adulterated in search of
                    money making. This is an important concern for the founders.
                    These all aspects which are happening with consumers were
                    the reason behind the formation of Upbhokta Adhikar Sangthan
                    (CRO - Consumer Rights Organization).
                  </div>
                  </div>
              </Col>
          </Row>
          </Container>
            {/* <div className="row">
              <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                <div className="inner-column">
                  <div className="sec-title">
                    <span className="title">About CRO</span>
                    <h2>History Behind The Formation Of CRO</h2>
                  </div>
                  <div className="text">
                    In 2010 Late Mr. D.P.Sharma and four activists started CRO
                    they felt that something needed to be done to improve the
                    situation of the common consumer. In this age the moral
                    ethics in trade or business has fallen on ground for maximum
                    profits. Even eatables are being adulterated in search of
                    money making. This is an important concern for the founders.
                    These all aspects which are happening with consumers were
                    the reason behind the formation of Upbhokta Adhikar Sangthan
                    (CRO - Consumer Rights Organization).
                  </div>
                  <div className="btn-box">
                    <Link
                      to="/complaint/contact"
                      className="theme-btn btn-style-one"
                      style={{ borderRadius: 10 }}
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              {/* Image Column */}
              {/* <div className="image-column col-lg-6 col-md-12 col-sm-12">
                <div className="inner-column wow fadeInLeft">
                  <figure className="image-1">
                    <a
                      href="#"
                      className="lightbox-image"
                      data-fancybox="images"
                    >
                      <img
                        title="Rahul Kumar Yadav"
                        src={slider4}
                        alt
                        style={{ height: "15.5rem" }}
                      />
                    </a>
                  </figure>
                  <figure className="image-1">
                    <a
                      href="#"
                      className="lightbox-image"
                      data-fancybox="images"
                    >
                      <img
                        title="Rahul Kumar Yadav"
                        src={slider3}
                        alt
                        style={{ height: "15.5rem" }}
                      />
                    </a>
                  </figure>
                </div>
              </div>
            </div> */} 


            <Container fluid>
            <div className="sec-title mt-5">
              {/* <span className="title">Our Future Goal</span> */}
              <h2>Introduction to Consumer Rights Organisation</h2>
            </div>
            <div className="text">
              Upbhokta Adhikar Sangthan Regd. No. 05/23/01/08574/10, we are
              India’s largest organization, working Pan India basis having
              presence in 24 states and union territories of India.
            </div>
            <div className="text">
              We are working for Consumer Awareness, Consumer Education and
              Consumer Protection. We work on specially designed activities to
              aware consumers regarding Consumer Rights, Responsibilities, Food
              Quality, Food Fortification, CPA, Consumer Grievance, duplicate
              product, adulterated food products, misleading advertisements etc.
              From past 13 years our teams in various parts of India is
              regularly conducting Training, Survey, Signature Drive, Workshop,
              Public Meetings, Rally, Seminar in schools/colleges and Ground
              Exhibition in Public places in Rural & Urban areas so that more
              and more people can connect with us and know their rights as a
              responsible consumers.
            </div>
            <div className="text">
              Of the several laws that have been enacted to protect the rights
              of consumers in India, the most significant is the Consumer
              Protection Act, 2019. Under this law, everyone, including
              individuals, a Hindu undivided family, a firm, and a company, can
              exercise their consumer rights for the goods and services
              purchased by them. It is important that, as consumers, we know at
              least our basic rights and about the courts and procedures that
              deal with the infringement of our rights.
            </div>
            </Container>
        </section>
        <div className="container-fluid ">
          <div className="row bg-light px-5 py-3">
            <h4 className="text-center mb-4 mt-4 fw-bolder">OUR OBJECTIVES</h4>
            <div className="col-md-6 mt-3">
              <ul>
                <li
                  className="mb-3"
                  style={{ fontSize: "1rem", listStyle: "disc" }}
                >
                  Organize and aware consumers
                </li>
                <li
                  className="mb-3"
                  style={{ fontSize: "1rem", listStyle: "disc" }}
                >
                  Protect and publicize all the rights of consumer
                </li>
                <li
                  className="mb-3"
                  style={{ fontSize: "1rem", listStyle: "disc" }}
                >
                  Execute the programs related to consumer education, their
                  rights and their responsibilities, with the help of government
                  and administration
                </li>
                <li
                  className="mb-3"
                  style={{ fontSize: "1rem", listStyle: "disc" }}
                >
                  Actively provide support in proper implementation of Consumer
                  Protection Act
                </li>
                <li
                  className="mb-3"
                  style={{ fontSize: "1rem", listStyle: "disc" }}
                >
                  Make best effort to stop monopolistic, restrictive and unfair
                  trade practices
                </li>
                <li
                  className="mb-3"
                  style={{ fontSize: "1rem", listStyle: "disc" }}
                >
                  Give and receive moral support for the consumer interest to
                  other organizations while working under the rules of the
                  organization
                </li>
                <li
                  className="mb-3"
                  style={{ fontSize: "1rem", listStyle: "disc" }}
                >
                  Put into practice statutory measures under the civil rights
                  for the achievement of Organizational objectives
                </li>
              </ul>
            </div>
            <div className="col-md-6 mt-3">
              <ul>
                <li
                  className="mb-3"
                  style={{ fontSize: "1rem", listStyle: "disc" }}
                >
                  To protect consumers interests, their basic needs and rights
                  such as health, education, water, electricity, roads,
                  entertainment, catering, traffic, etc
                </li>
                <li
                  className="mb-3"
                  style={{ fontSize: "1rem", listStyle: "disc" }}
                >
                  Operate consumer information center in all districts of India
                  with the help of government
                </li>
                <li
                  className="mb-3"
                  style={{ fontSize: "1rem", listStyle: "disc" }}
                >
                  Organizing and conducting consumer related programs to get aid
                  and grants from the central government, state governments,
                  agencies and organizations
                </li>
                <li
                  className="mb-3"
                  style={{ fontSize: "1rem", listStyle: "disc" }}
                >
                  Through the organization implement and operate the various
                  consumer related schemes of Central Government and State
                  Governments
                </li>
                <li
                  className="mb-3"
                  style={{ fontSize: "1rem", listStyle: "disc" }}
                >
                  Through the organization legally present the consumer problems
                  and complaints before the Consumer Forum and the Commission
                </li>
                <li
                  className="mb-3"
                  style={{ fontSize: "1rem", listStyle: "disc" }}
                >
                  Solve problems of general consumers and our members
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutCro;
