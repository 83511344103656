import React from 'react'
import img from "./assets/error.png"
import { useNavigate } from 'react-router-dom'

const NoPage = () => {
 const navigate =  useNavigate()
  return (
    <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"100vh",}}>
      <div style={{width:"50%",height:"50%",display:"block"}}>
      <img src={img} style={{width:350,height:350}} className='mx-auto d-block'/>
         
      <button onClick={()=>navigate('/')} className='mx-auto d-block'>Go Home</button>
      </div>
    </div>
  )
}

export default NoPage 