import React, { useEffect,useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Outlet, Link } from "react-router-dom";
import { FaChevronDown, FaBars } from "react-icons/fa";
import {
  AiOutlineLogout,
  AiOutlineHome,
} from "react-icons/ai";
import { MdOutlineNoteAlt } from "react-icons/md";
import { BsGear } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import logo from "../../../assets/logo.png";

import "../../../assets/css/componentcss/SuperAdminDashboard.css";
import "../../../assets/css/componentcss/AdminDashboard.css";
import PendingComplaints from "../admin/complaints/PendingComplaints";
import AcceptedComplaints from "../admin/complaints/AcceptedComplaints";
import DeclinedComplaints from "../admin/complaints/DeclinedComplaints";
import ResolvedComplaints from "../admin/complaints/ResolvedComplaints";
import UserSettings from "./UserSettings";
import { GiHamburgerMenu } from "react-icons/gi";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useQuery } from "@apollo/client";
import { GET_USER_BY_ID } from "../../../graphql/Query";

export default function UserDashboard() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // useEffect(() => {
  //   var dropdown = document.querySelectorAll(".dropdown");
  //   var flag = 1;
  //   dropdown.forEach((item) => {
  //     item.addEventListener("click", function () {
  //       if (flag === 1) {
  //         item.classList.add("active");
  //         flag = 0;
  //       } else {
  //         item.classList.remove("active");
  //         flag = 1;
  //       }
  //     });
  //   });
  // }, []);

  // useEffect(() => {
  //   var sidebar = document.querySelector(".sidebar");
  //   var flag2 = 0;
  //   const menu = document.querySelector(".bx-menu");
  //   menu.addEventListener("click", function () {
  //     if (flag2 == 0) {
  //       sidebar.classList.add("close");
  //       flag2 = 1;
  //     } else {
  //       sidebar.classList.remove("close");
  //       flag2 = 0;
  //     }
  //   });
  // }, []);

  useEffect(() => {
    if (!localStorage.getItem("userToken")) {
      navigate("/complaint/userLogin");
    }
  }, [navigate]);

  function logoutHandel() {
    localStorage.removeItem("userToken");
    localStorage.removeItem("userId");
    navigate("/complaint");
  }
   


let userId = localStorage.getItem('userId')

console.log('userId',userId)

  const{data,loading} =  useQuery(GET_USER_BY_ID,{
    variables:{
     "userId": `${localStorage.getItem('userId')}`
    }
  })

  console.log('data',data)


  
 
  

  return (
    <>
    <div style={{display:"flex",justifyContent:"space-between",height:60,background:"#fff ",alignItems:"center"}}>
      <div style={{marginLeft:10,display:"flex",height:"100%",alignItems:"center"}}>
       <img src="https://cdn-icons-png.flaticon.com/512/10337/10337609.png" style={{width:40,height:40}}/>
       <h6 style={{fontSize:17,fontFamily:"Poppins",marginLeft:10,marginTop:5,color:"#000"}}>{data && data.getUserById.fullName}</h6>
      </div>
     <GiHamburgerMenu  style={{marginRight:20}} size={25} onClick={handleShow}/>
    </div>
    <br></br>
   
    <Outlet />
     
    <Offcanvas show={show} onHide={handleClose} style={{width:300,}}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
          <div style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%"}}>
           <div style={{width:"35%",display:"flex",alignItems:"center",marginLeft:2}}>
           <Link className="navbar-brand" to="/">
              <img
                src={logo}
                className="d-inline-block mb-2"
                height={50}
                width={50}
                alt=""
              />
            </Link>
            </div>
            <h5
                style={{
                  fontSize:12,
                  fontFamily: "Poppins, serif",
                  color:"#000"
                }}
              >
                उपभोक्ता अधिकार संगठन <br />
                <span
                  style={{
                    fontSize:12,
                    fontFamily: "Poppins, serif",
                    color:"#000"
                  }}
                >
                  Consumer Rights Organisation
                </span>
              </h5>
           </div>

          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          
          <ul style={{padding:10,cursor:"pointer"}}>
            <li style={{fontFamily:"Poppins",fontWeight:"600",fontSize:16}} onClick={()=>handleClose()}>
            <Link
                to="/consumer-complaint-with-consumer-right-helpline"
                className="link"
                style={{ textDecoration: "none",color:"#000" }}
                
              >
             Home
            </Link>
            </li>
            <li style={{marginTop:10,fontFamily:"Poppins",fontWeight:"600",fontSize:16}} onClick={()=>handleClose()} >
            <Link
                to="/complaint/userDashboard/pendingComplaintsUser"
                className="link"
                style={{ textDecoration: "none",color:"#000" }}
              >
              Pending complaints
            </Link>
            </li>
            <li style={{marginTop:10,fontFamily:"Poppins",fontWeight:"600",fontSize:16}} onClick={()=>handleClose()}>
            <Link
                to="/complaint/userDashboard/acceptedComplaintsUser"
                className="link"
                style={{ textDecoration: "none",color:"#000" }}
              >
              Accepted complaints
             </Link>
            </li>
            <li style={{marginTop:10,fontFamily:"Poppins",fontWeight:"600",fontSize:16}} onClick={()=>handleClose()}>
            <Link
                to="/complaint/userDashboard/declinedComplaintsUser"
                className="link"
                style={{ textDecoration: "none",color:"#000" }}
              >
              Declined complaints
              </Link>
              </li>
            <li style={{marginTop:10,fontFamily:"Poppins",fontWeight:"600",fontSize:16}} onClick={()=>handleClose()}>
            <Link
                to="/complaint/userDashboard/resolvedComplaintsUser"
                className="link"
                style={{ textDecoration: "none",color:"#000" }}
              >
              Resolved complaints
              </Link>
              </li>
            <li style={{marginTop:10,fontFamily:"Poppins",fontWeight:"600",fontSize:16}} onClick={()=>handleClose()}>
            <Link
                to="/complaint/userDashboard/userSettings"
                className="link"
                style={{ textDecoration: "none" ,color:"#000"}}
              >
              Settings
              </Link>
              </li>
            <li style={{marginTop:10,fontFamily:"Poppins",fontWeight:"600",fontSize:16}}  onClick={()=>logoutHandel()}>Log Out</li>
          </ul>


        </Offcanvas.Body>
      </Offcanvas>
   
    </>
  );
}
