import React, { useState } from "react";
import { Container, Row, Col, Table, Button, Modal } from "react-bootstrap";
import { FaEye } from "react-icons/fa";

import { GET_RESOLVED_COMPALINT_BY_USERID } from "../../../../graphql/Query";
import { useQuery } from "@apollo/client";
import Moment from "react-moment";
import complain from "../../../../assets/pic/complain.png"
import { useNavigate, useNavigation } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";

export default function ResolvedComplaintsUser() {
  const userId = localStorage.getItem("userId");

  const { data } = useQuery(GET_RESOLVED_COMPALINT_BY_USERID, {
    variables: {
      userId: `${userId}`,
    },
    pollInterval: 300,
  });

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedComplaint, setSelectedComplaint] = useState({});

  const clickShowDetailsModal = (cId) => {
    const selectedComplaint = data.getResolvedComplaintByUserId.find(
      (Cdata) => Cdata.id === cId
    );
    setSelectedComplaint(selectedComplaint);
    setShowDetailsModal(true);
    console.log(selectedComplaint);
  };

  const navigation = useNavigate();

  return (
    <>
      <Container>
        {
           data && data.getResolvedComplaintByUserId.length === 0 || data === undefined?
           <>
           <img src={complain} style={{width:250,height:250}} className="mx-auto d-block"/>
           <button onClick={()=>navigation('/complaint/register-a-consumer-complaint-with-consumer-right-helpline-thecro')}  style={{background:"transparent",color:"#000",width:200,border:"2px solid #000",fontFamily:"Poppins"}} className="mx-auto d-block"> 
           File Complaint
          <FaArrowRight style={{marginLeft:5,marginTop:-3,color:"#000"}}/>
          </button>
           </>
           :
           <>
             <h1
              style={{
                fontFamily: "poppins",
                fontSize: 20,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Resolved Complaints
            </h1>
            <Table bordered style={{ fontFamily: "poppins", fontSize: 12 }} responsive>
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Complaint Id</th>
                  <th>Date/Time</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.getResolvedComplaintByUserId
                    .slice()
                    .reverse()
                    .map((Cdata, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{Cdata.complaintId}</td>
                        <td>
                          <Moment format="DD-MM-YYYY, hh:mm A">
                            {Cdata.createdDateTime}
                          </Moment>
                        </td>
                        <td>{Cdata.complaintType}</td>
                        <td>{Cdata.status}</td>
                        <td>
                          <Button
                            size="sm"
                            variant="success"
                            onClick={() => clickShowDetailsModal(Cdata.id)}
                          >
                            <FaEye />
                          </Button>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </Table>
        
           
           </>
          
        }
       
          
      </Container>

      {/* modal for show the details of the complaint */}
      <Modal
        show={showDetailsModal}
        onHide={() => setShowDetailsModal(false)}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ width: "100%" }}>
          <Col md={12}>
            <Row md={12}>
              <h1
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontFamily: "poppins",
                  fontSize: 25,
                }}
              >
                Complaint Details
              </h1>
              <Table
                bordered
                style={{ fontFamily: "poppins", fontSize: 12 }}
                responsive
              >
                <tbody>
                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Details of opposite party
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>
                      Company/Firm/Service Provider Name
                    </td>
                    <td>{selectedComplaint.yourProviderName}</td>
                    <td style={{ fontWeight: "bold" }}>Email</td>
                    <td>{selectedComplaint.yourEmail}</td>
                    <td style={{ fontWeight: "bold" }}>Contact</td>
                    <td>{selectedComplaint.yourContact}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>
                      Name of concern person
                    </td>
                    <td>{selectedComplaint.concernPerson}</td>
                    <td style={{ fontWeight: "bold" }}>Name of product</td>
                    <td>{selectedComplaint.productName}</td>
                    <td style={{ fontWeight: "bold" }}>Address</td>
                    <td>{selectedComplaint.yourFullAddress}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>City</td>
                    <td>{selectedComplaint.yourCity}</td>
                    <td style={{ fontWeight: "bold" }}>State</td>
                    <td>{selectedComplaint.yourState}</td>
                    <td style={{ fontWeight: "bold" }}>Pincode</td>
                    <td>{selectedComplaint.yourPincode}</td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      {" "}
                      Have You Complained To Anyother Platform Earlier
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>
                      Have You Complained To Anyother Platform Earlier
                    </td>
                    <td>{selectedComplaint.haveYouComplaintToAnother}</td>
                    <td style={{ fontWeight: "bold" }}>Name of Platform</td>
                    <td colspan={3}>
                      {selectedComplaint.haveYouComplaintToAnotherText}
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Resolution
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Resolution</td>
                    {selectedComplaint.resolution ? (
                      <td>
                        {selectedComplaint &&
                          selectedComplaint.resolution.map((d) => (
                            <h6 style={{ fontFamily: "poppins" }}>{d},</h6>
                          ))}
                      </td>
                    ) : (
                      ""
                    )}
                    <td style={{ fontWeight: "bold" }}>Name of Platform</td>
                    <td colspan={3}>
                      {selectedComplaint.haveYouComplaintToAnotherText}
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Product/Service Name
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9}>
                      {selectedComplaint.haveStartCourtActionText}
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Product Or Service Value (INR)
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9}>₹{selectedComplaint.productValue}</td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      How Would You Like Your Complaint To Get Solved?
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9}>
                      ₹{selectedComplaint.howYouLikeComplaintSolve}
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      When Did This Transaction / Problem Occur?
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Date</td>
                    <td>{selectedComplaint.whenProblemOccurDate}</td>
                    <td style={{ fontWeight: "bold" }}>Time</td>
                    <td colspan={3}>
                      {selectedComplaint.whenProblemOccurTime}
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Select A Type Which Is Most Relevant To Your Complaint
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9}>{selectedComplaint.complaintType}</td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Please Describe Your Complaint In Detail
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9}>
                      {selectedComplaint.complaintDescription}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Col>
        </Modal.Body>
      </Modal>
    </>
  );
}
