import React, { useState, useEffect } from "react";
import Navbar from "../navbar";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { MUTATION_GOOGLE_SIGN_IN, USER_LOGIN } from "../../../graphql/Mutation";
import { useNavigate, useLocation } from "react-router-dom";
import { useAlert } from "react-alert";
import "../../../assets/css/userlogin.css";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { Helmet } from "react-helmet";


export default function UserLogin() {

 const[googleSignUp,{data:dataGoogleSign,loading:loadingGoogle}] =  useMutation(MUTATION_GOOGLE_SIGN_IN)
 const[loadingState,setLoadingState] =useState(false)

 const alert = useAlert();

 const navigate = useNavigate();
 const location = useLocation();

  console.log('dataGoogleSign',dataGoogleSign)

  const handleSuccess =async (response) => {
    setLoadingState(true)
    const decoded = jwtDecode(response.credential);
     // Split the full name into first and last name
     console.log('decoded',decoded)
    
    await googleSignUp({
      variables:{
        "fullName": `${decoded.name}`,
        "email": `${decoded.email}`,
      }
    }).then(()=>{
      setLoadingState(false)
      
    })
  };

   if(dataGoogleSign){
    localStorage.setItem("userToken",dataGoogleSign && dataGoogleSign.googleSignUp.consumerToken);
    localStorage.setItem("userId", dataGoogleSign && dataGoogleSign.googleSignUp.consumerId);
    navigate("/complaint/userDashboard/pendingComplaintsUser");
   }




  const [validated, setValidated] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // console.log("locaion", location.state.pathName)
  const [userLogin, { loading }] = useMutation(USER_LOGIN, {
    onCompleted: ({ signInUser }) => {
      if (signInUser.isPremium) {
        localStorage.setItem("userToken", signInUser.userToken);
        localStorage.setItem("userId", signInUser.userId);
        navigate("/complaint/userDashboard/pendingComplaintsUser");
      } else {
        sessionStorage.setItem("browser-id", signInUser.userId);
        navigate("/complaint/get-membership");
      }
    },
    onError: (e) => {
      console.log(e);
      alert.error("Username & Password not match!!!");
    },
  });

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(false);
      event.preventDefault();
      sessionStorage.removeItem("browser-id");
      userLogin({
        variables: {
          signInUserInput: {
            email: `${email}`,
            password: `${password}`,
          },
        },
      }).then(() => {
        setEmail("");
        setPassword("");
      });
    }
  };

  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      navigate("/complaint/userDashboard/pendingComplaintsUser");
    }
  }, [navigate]);




  return (
    <div className="loginContainer">
      <Helmet>
        <title>Consumer Rights Portal – Login</title>
        <meta
          name="description"
          content="Log in to access your consumer rights account, manage your claims, and get support on your consumer rights."
        />
        <meta name="robots" content="index, follow" />
         {/* Open Graph / Facebook Meta Tags */}
         <meta property="og:title" content="Consumer Rights Portal – Login" />
        <meta
          property="og:description"
          content="Access your consumer rights account, file claims, and get support to protect your interests."
        />
        <meta
          property="og:image"
          content="https://growhealthproimg.s3.ap-south-1.amazonaws.com/consumer-right-image.jpg"
        />
        <meta property="og:url" content="https://www.thecro.org/complaint/userLogin" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="CRO-Consumer Rights Organisation" />
        <meta name="twitter:title" content="CRO-Consumer Rights Organisation" />
        <meta
          name="twitter:description"
          content="Log in to access your consumer rights account and learn how to protect your consumer rights."
        />
        <meta
          name="twitter:image"
          content="https://growhealthproimg.s3.ap-south-1.amazonaws.com/consumer-rights.jpg"
        />
      </Helmet>

      <Navbar />
    

              <Card
              style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", }}
              className="login-card"
            >
              <h1
                style={{
                  fontFamily: "poppins",
                  fontSize: 25,
                  textAlign: "center",
                  marginTop: 20,
                }}
              >
                SIGN IN NOW
              </h1>
              <Card.Body>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                  style={{ marginTop: "-30px" }}>
                
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                      <Form.Label
                        style={{
                          fontFamily: "poppins",
                          fontSize: 12,
                          fontWeight: "bold",
                        }}
                      >
                        Email
                      </Form.Label>
                      <Form.Control
                        style={{ fontFamily: "poppins", fontSize: 12 }}
                        required
                        type="text"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                    </Form.Group>
                 
                 
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                      <Form.Label
                        style={{
                          fontFamily: "poppins",
                          fontSize: 12,
                          fontWeight: "bold",
                        }}
                      >
                        Password
                      </Form.Label>
                      <Form.Control
                        style={{ fontFamily: "poppins", fontSize: 12 }}
                        required
                        type="password"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                      />
                    </Form.Group>
                    <br></br>
                  {loading ? (
                    <Spinner
                      animation="border"
                      role="status"
                      className="mx-auto d-block"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    <Button
                      type="submit"
                      className="mx-auto d-block loginBtnn"
                      style={{ width: "100%",fontSize:15,fontFamily: "poppins" }}
                    >
                      Sign In
                    </Button>
                  )}
                  <br></br>

                {loading ? (
                    <Spinner
                      animation="border"
                      role="status"
                      className="mx-auto d-block"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    <>
                    {
                      loadingState ?
                     <Spinner
                      animation="border"
                      role="status"
                      className="mx-auto d-block"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                      :
                      <GoogleLogin onSuccess={handleSuccess}  />
                    }
                    
                    
                    </>
                  )}
                  <h1
                    style={{
                      fontFamily: "poppins",
                      fontSize: 12,
                      textAlign: "center",
                      color: "#95a5a6",
                      marginTop: 20,
                    }}
                  >
                    Don't have an account?{" "}
                    <Link to="/complaint/register" style={{ color: "#ff5748" }}>
                      Register Now
                    </Link>
                  </h1>
                  <Link to="/complaint/ForgotPasswordSendEmail">
                    <h1
                      style={{
                        fontFamily: "poppins",
                        fontSize: 12,
                        textAlign: "center",
                        fontWeight: "bold",
                        color: "#95a5a6",
                      }}
                    >
                      Forgot Password?
                    </h1>
                  </Link>
                </Form>
              </Card.Body>
            </Card>
       
      
    </div>
  );
}
